<svg  
width="32"
height="32"
viewBox="0 0 10 8.5"
   id="svg5"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"
   class={$$props.class}
   fill="currentColor" 
>
   <style>
    /* Note that the color of the text is set with the    *
     * fill property, the color property is for HTML only */
    .tex {
      font-weight:700;
      font-stretch:ultra-condensed;
      font-size:12px;
      line-height:1.25;
      font-family:inherit;
      -inkscape-font-specification:'League Gothic, Ultra-Condensed';
      /* text-align:end; */
      /* text-anchor:end; */
      /* stroke-width:0.264583; */
    }
  </style>
  <text x="0" y="14"  transform="translate(1,-6) scale(0.5 1)" class="tex">P</text>
  <text x="14" y="14" transform="translate(6.5,-0.5) scale(0.3 0.6)" class="tex">ixelArt</text>  
</svg>
