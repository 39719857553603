import preambule from '../lib/latex/preambule.tex?raw'
import TypeExercice from '../exercices/ExerciceTs.js'
import { mathaleaHandleExerciceSimple } from './aleatex.js'
import seedrandom from 'seedrandom'
// printPrettier pose problème avec begin{aligned}[t] en ajoutant un saut de ligne problématique
// import { printPrettier } from 'prettier-plugin-latex/standalone.js'
export interface Exo {
  content?: string
  serie?: string
  month?: string
  year?: string
  zone?: string
  title?: string
}

export interface picFile {
  name: string
  format: string
}

export interface datFile {
  name: string
  format: string
}

export interface pyFile {
  name: string
  format: string
}
export interface LatexFileInfos {
  style: 'PfMFiche' | 'PfMCan' | 'PfMBeamer' | 'PfMAmc' | 'PfMPixelArt'
  nbVersions: number
  dys: boolean
  theme: string
  sousTheme: string
  date: string
  niveau: string
  classe: string
  corrige: 'CorrigeFin' | 'CorrigeApres' | ''
  twocolumn: boolean
  beamerTwocolumn: boolean
  didactiques: boolean
  title: string
  subtitle: string
  reference: string
  pixelArtUnite: number
  pixelArtCsv: {filename:string; letters: string, colors: string}
  pixelArtAleatoire: boolean
  pixelArtCoupeTableau: number
}

interface ExoContent {
  content?: string
  serie?: string
  month?: string
  year?: string
  zone?: string
  title?: string
}

class Latex {
  exercices: TypeExercice[]
  latexPreambule: string
  latexPostambule: string
  constructor () {
    this.exercices = []
    this.latexPreambule = preambule
    this.latexPostambule = '\n\\end{document}'
  }

  addExercices (exercices: TypeExercice[]) {
    this.exercices.push(...exercices)
  }

  getContentsForAVersion (
    style: 'Coopmaths' | 'Classique' | 'PfMFiche' | 'PfMCan' | 'PfMBeamer' | 'PfMAmc' | 'PfMPixelArt' | 'ProfMaquetteQrcode' | 'Can',
    indiceVersion: number = 1
  ): { content: string; contentCorr: string } {
    console.info('getContentsForAVersion')
    if (style === 'PfMFiche' || style === 'PfMCan' || style === 'PfMBeamer' || style === 'PfMAmc' || style === 'PfMPixelArt') return { content: this.getContentForAVersionProfMaquette(1, false), contentCorr: '' }
    if (style === 'ProfMaquetteQrcode') return { content: this.getContentForAVersionProfMaquette(1, true), contentCorr: '' }
    let content = ''
    let contentCorr = ''
    for (const exercice of this.exercices) {
      if (exercice.typeExercice === 'statique') continue
      if (!Object.prototype.hasOwnProperty.call(exercice, 'listeQuestions')) continue
      if (exercice != null) {
        const seed = indiceVersion > 1 ? exercice.seed + indiceVersion.toString() : exercice.seed
        exercice.seed = seed
        if (exercice.typeExercice === 'simple') mathaleaHandleExerciceSimple(exercice, false)
        seedrandom(seed, { global: true })
        if (typeof exercice.nouvelleVersion === 'function') exercice.nouvelleVersion()
      }
    }
    if (style === 'Can') {
      content += '\\begin{TableauCan}\n'
      contentCorr += '\n\\begin{enumerate}'
      for (const exercice of this.exercices) {
        if (exercice != null) {
          for (let i = 0; i < exercice.listeQuestions.length; i++) {
            if (exercice.listeCanEnonces != null && exercice.listeCanEnonces[i] !== undefined && exercice.listeCanReponsesACompleter != null && exercice.listeCanReponsesACompleter[i] !== undefined) {
              content += `\\thenbEx  \\addtocounter{nbEx}{1}& ${format(exercice.listeCanEnonces[i])} &  ${format(
                  exercice.listeCanReponsesACompleter[i]
              )} &\\tabularnewline \\hline\n`
            } else {
              content += `\\thenbEx  \\addtocounter{nbEx}{1}& ${format(exercice.listeQuestions[i])} &&\\tabularnewline \\hline\n`
            }
          }
          for (const correction of exercice.listeCorrections) {
            contentCorr += `\n\\item ${format(correction)}`
          }
        }
      }
      content += '\\end{TableauCan}\n\\addtocounter{nbEx}{-1}'
      /** On supprime les lignes vides car elles posent problème dans l'environnement TableauCan */
      content = content.replace(/\n\s*\n/gm, '')
    } else {
      for (const exercice of this.exercices) {
        if (exercice.typeExercice === 'statique') {
          if (exercice.content === '') {
            content += '% Cet exercice n\'est pas disponible au format LaTeX'
          } else {
            if (style === 'Coopmaths') {
              content += `\n\\begin{EXO}{${exercice.examen || ''} ${exercice.mois || ''} ${exercice.annee || ''} ${exercice.lieu || ''}}{}\n`
            } else if (style === 'Classique') {
              content += '\n\\begin{EXO}{}{}\n'
            }
            if (Number(exercice.nbCols) > 1) {
              content += `\\begin{multicols}{${exercice.nbCols}}\n`
            }
            content += exercice.content
            if (Number(exercice.nbCols) > 1) {
              content += '\n\\end{multicols}\n'
            }
            content += '\n\\end{EXO}\n'
            contentCorr += '\n\\begin{EXO}{}{}\n'
            contentCorr += exercice.contentCorr
            contentCorr += '\n\\end{EXO}\n'
          }
        } else {
          contentCorr += '\n\\begin{EXO}{}{}\n'
          if (Number(exercice.nbColsCorr) > 1) {
            contentCorr += `\\begin{multicols}{${exercice.nbColsCorr}}\n`
          }
          if (Number(exercice.spacingCorr) > 0) {
            contentCorr += `\n\\begin{enumerate}[itemsep=${exercice.spacingCorr}em]`
          } else {
            contentCorr += '\n\\begin{enumerate}'
          }

          for (const correction of exercice.listeCorrections) {
            contentCorr += `\n\\item ${format(correction)}`
          }
          if (Number(exercice.nbColsCorr) > 1) {
            contentCorr += '\\end{multicols}\n'
          }
          contentCorr += '\n\\end{EXO}\n'
          content += `\n\\begin{EXO}{${format(exercice.consigne)}}{${String(exercice.id).replace('.js', '')}}\n`
          content += writeIntroduction(exercice.introduction)
          content += writeInCols(writeQuestions(exercice.listeQuestions), Number(exercice.nbCols))
          content += '\n\\end{EXO}\n'
        }
      }
    }
    return { content, contentCorr }
  }

  getContentForAVersionProfMaquette (indiceVersion: number = 1, withQrcode = false): string {
    let content = ''
    for (const exercice of this.exercices) {
      if (exercice.typeExercice === 'statique') continue
      const seed = indiceVersion > 1 ? exercice.seed + indiceVersion.toString() : exercice.seed
      exercice.seed = seed
      if (exercice.typeExercice === 'simple') mathaleaHandleExerciceSimple(exercice, false)
      seedrandom(seed, { global: true })
      if (typeof exercice.nouvelleVersion === 'function') exercice.nouvelleVersion()
    }
    for (const exercice of this.exercices) {
      content += `\n\t% @see : ${getUrlFromExercice(exercice)}`
      content += '' // Ajout
      // console.info(exercice)
      if (exercice.typeExercice === 'statique') {
        if (exercice.content === '') {
          content += '% Cet exercice n\'est pas disponible au format LaTeX'
        } else {
          content += `\n ${exercice.content}`
          content += exercice.contentCorr
        }
      } else {
        if (exercice.preLatex) { content += exercice.preLatex }
        // if (withQrcode) { content += '\n\\begin{minipage}{0.75\\linewidth}' }
        content += writeIntroduction(exercice.introduction)
        if (exercice.consigne) { content += '\n' + format(exercice.consigne) }
        // content += writeInCols(writeQuestions(exercice.listeQuestions), Number(exercice.nbCols))
        content += writeQuestions(exercice.listeQuestions)
        // if (withQrcode) {
        //   content += '\n\\end{minipage}'
        //   content += '\n\\begin{minipage}{0.20\\linewidth}'
        //   content += `\n\\qrcode{${getUrlFromExercice(exercice)}&v=eleve&es=0211}`
        //   content += '\n\\end{minipage}'
        // }
        if (exercice.postLatex) { content += '\n' + exercice.postLatex }
        if (exercice.preLatexCorrection) { content += '\n' + exercice.preLatexCorrection }
        // Pour la restauration des variables TeX
        if (exercice.consigneCorrection) { content += '\n' + format(exercice.consigneCorrection) }
        content += writeInCols(writeQuestions(exercice.listeCorrections), Number(exercice.nbColsCorr))
        if (exercice.postLatexCorrection) { content += '\n' + exercice.postLatexCorrection }
      }
    }
    return content
  }

  async getContents (
    style: 'PfMFiche' | 'PfMCan' | 'PfMBeamer' | 'PfMAmc' | 'PfMPixelArt',
    nbVersions: number = 1,
    dys: boolean = false,
    theme: string = '',
    sousTheme: string = '',
    date: string = '',
    niveau: string = '',
    classe: string = '',
    corrige: string = 'CorrigeFin',
    twocolumn: boolean = false,
    beamerTwocolumn: boolean = true,
    didactiques: boolean = false,
    title: string = '',
    subtitle: string = '',
    reference: string = '',
    pixelArtUnite: number = 4,
    pixelArtCsv = {
      filename: 'CitrouilleSorciere.csv',
      letters: 'ABCDEFGHI',
      colors: 'black,Orange,Orange,Yellow,white,Purple,white,black,white'
    },
    pixelArtAleatoire = false,
    pixelArtCoupeTableau = 0
  ): Promise<{ content: string; contentCorr: string }> {
    const contents = { content: '', contentCorr: '' }
    if (style === 'PfMFiche' || style === 'PfMCan' || style === 'PfMBeamer' || style === 'PfMAmc' || style === 'PfMPixelArt') {
      // On ajoute le documentclass
      switch (style) {
        case 'PfMCan':
        case 'PfMFiche':
        case 'PfMPixelArt':
          contents.content += `%!TEX lualatex\n\\documentclass[french${twocolumn === true ? ',twocolumn' : ''}]{article}\n`
          break
        case 'PfMBeamer':
          contents.content += '%!TEX lualatex\n\\PassOptionsToPackage{luatex}{hyperref}\n\\documentclass[french,t]{beamer}\n'
          break
        case 'PfMAmc':
          contents.content += '%!TEX lualatex\n\\documentclass[12pt,a4paper,french]{article}\n'
          break
        default:
          console.info(`Cas ${style} non prévu pour le style LaTeX`)
      }
      // On ajoute le preambule
      switch (style) {
        case 'PfMCan':
          contents.content += this.latexPreambule
            .replace('\\usepackage{ProfMaquette}', '\\usepackage[CAN]{ProfMaquette}')
            .replace('a4paper', 'a5paper')
            .replace('\\input{pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
            .replace('\\input{./commandes/pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
          break
        case 'PfMPixelArt':
        case 'PfMFiche':
          contents.content += this.latexPreambule
            .replace('\\input{pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
            .replace('\\input{./commandes/pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
          break
        case 'PfMBeamer':
          contents.content += this.latexPreambule
            .replace('\\usepackage[a4paper,margin=1cm,nohead,includefoot]{geometry}', '%')
            .replace('\\setlength{\\parindent}{0pt}', '%')
            .replace('\\pagestyle{empty}', '%')
            .replace('\\input{pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
            .replace('\\input{./commandes/pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
          break
        case 'PfMAmc':
          contents.content += this.latexPreambule
            .replace(
              '\\usepackage[a4paper,margin=1cm,nohead,includefoot]{geometry}',
              '%'
            )
            .replace('\\setlength{\\parindent}{0pt}', '%')
            .replace('\\pagestyle{empty}', '%')
          break
        default:
          console.info(`Cas ${style} non prévu pour le style LaTeX`)
      }
      // contents.content += style === 'PfMCan' ? this.latexPreambule.replace('\\usepackage{ProfMaquette}', '\\usepackage[CAN]{ProfMaquette}').replace('a4paper', 'a5paper') : this.latexPreambule
      const contentVersion = this.getContentForAVersionProfMaquette(1, false)
      // On ajoute éventuellement des paquets spécifiques
      const [latexCmds, latexPackages] = this.getContentLatex()
      if (latexPackages.length !== 0) {
        contents.content += '%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%'
        contents.content += '\n%Des paquets spécifiques à l\'exo'
        contents.content += '\n%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%'
        for (const pack of latexPackages) {
          contents.content += `\n\\usepackage{${pack}}`
        }
        contents.content += '\n%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%'
      }
      if (latexCmds.length !== 0) {
        contents.content += '\n%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%'
        contents.content += '\n%Des commandes spécifiques à l\'exo'
        contents.content += '\n%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%'
        for (const cmd of latexCmds) {
          contents.content += '\n' + cmd.replace('cmd', '')
        }
        contents.content += '\n%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%'
      }
      // console.info(contents.content)
      contents.content += '\n\\begin{document}'
      // On gère la Maquette et le nombre de versions
      switch (style) {
        case 'PfMCan':
        case 'PfMFiche':
          contents.content += `\n\\begin{Maquette}[${style === 'PfMCan' ? 'CAN' : 'Fiche'}${corrige === '' ? '' : ','}${corrige}${dys === true ? ',Dys' : ''}]{Theme={${theme || 'Exercices'}},Niveau={${niveau || ''}},Classe={${classe || ''}},Date={${nbVersions > 1 ? `${date !== '' ? date + ' - v#1' : 'v#1'}` : `${date !== '' ? date : ''}`}}}`
          break
        case 'PfMPixelArt':
          console.info(pixelArtCsv)
          contents.content += `\n\\begin{Maquette}[PixelArt${dys === true ? ',Dys' : ''}]{Theme={${theme || 'PixelArt'}},Lettres={${pixelArtCsv.letters}},Unite={${pixelArtUnite}mm},ListeCouleurs={${pixelArtCsv.colors}},Fichier={"${pixelArtCsv.filename}"}${pixelArtAleatoire === true ? ',Aleatoire' : ''}${pixelArtCoupeTableau !== 0 ? `,CoupeTableau=${pixelArtCoupeTableau}` : ''}}`
          break
        case 'PfMBeamer':
          contents.content += `\n\\begin{Maquette}[${corrige}]{Theme={${theme || 'Exercices'}},SousTheme={${sousTheme || ''}},Niveau={${niveau || ''}},Classe={${classe || ''}},Date={${date !== '' ? date : ''}}}`
          break
        case 'PfMAmc':
          contents.content += `\n\\csvreader[head to column names]{listeElevesFactice.csv}{}{%\n\\exemplaire{1}{%\n\\begin{Maquette}[AMC${dys === true ? ',Dys' : ''}]{Theme={${theme || 'Exercices'}},,Niveau={${niveau || ''}},Date={${date !== '' ? date : '\\today'}}}`
          break
        default:
          console.info(`Cas ${style} non prévu pour le style LaTeX`)
      }
      // console.info(contentVersion)
      contents.content += contentVersion
      switch (style) {
        case 'PfMCan':
        case 'PfMFiche':
        case 'PfMBeamer':
        case 'PfMPixelArt':
          contents.content += '\n\\end{Maquette}'
          break
        case 'PfMAmc':
          contents.content += '\n\\end{Maquette}\n}\n}'
          break
        default:
          console.info(`Cas ${style} non prévu pour le style LaTeX`)
      }
      contents.contentCorr = ''
      contents.content += this.latexPostambule
      // }
    } else if (style === 'ProfMaquetteQrcode') {
      for (let i = 1; i < nbVersions + 1; i++) {
        const contentVersion = this.getContentForAVersionProfMaquette(i, true)
        contents.content += `\n\\begin{Maquette}[IE, CorrigeFin]{Theme={${title || 'Exercices'}},Niveau={${subtitle || ''}},Classe={${reference || ''}},Date={${nbVersions > 1 ? 'v' + i : ''}}}\n`
        contents.content += contentVersion
        contents.content += '\n\\end{Maquette}'
        contents.contentCorr = ''
      }
    } else {
      for (let i = 1; i < nbVersions + 1; i++) {
        const contentVersion = this.getContentsForAVersion(style, i)
        if (i > 1) {
          contents.content += '\n\\clearpage'
          contents.content += '\n\\setcounter{ExoMA}{0}'
          contents.contentCorr += '\n\\clearpage'
          contents.contentCorr += '\n\\setcounter{ExoMA}{0}'
        }
        if (nbVersions > 1) {
          contents.content += `\n\\version{${i}}`
          contents.contentCorr += `\n\\version{${i}}`
          if (i > 1 && style === 'Can') {
            contents.content += '\n\\setcounter{nbEx}{1}'
            contents.content += '\n\\pageDeGardeCan{nbEx}\n\\clearpage'
          }
        }
        contents.content += contentVersion.content
        contents.contentCorr += contentVersion.contentCorr
      }
    }
    return contents
  }

  async getFile ({
    style,
    nbVersions,
    dys,
    theme,
    sousTheme,
    date,
    niveau,
    classe,
    corrige,
    twocolumn,
    beamerTwocolumn,
    didactiques,
    title,
    subtitle,
    reference,
    pixelArtUnite,
    pixelArtCsv,
    pixelArtAleatoire,
    pixelArtCoupeTableau
  }: {
    style: 'PfMFiche' | 'PfMCan' | 'PfMBeamer' | 'PfMAmc' | 'PfMPixelArt'
    nbVersions: number
    dys: boolean
    theme: string
    sousTheme: string
    date: string
    niveau: string
    classe: string
    corrige: 'CorrigeFin' | 'CorrigeApres' | ''
    twocolumn: boolean
    beamerTwocolumn: boolean
    didactiques: boolean
    title: string
    subtitle: string
    reference: string
    pixelArtUnite: number,
    pixelArtCsv: {filename:string, letters:string, colors:string},
    pixelArtAleatoire: boolean,
    pixelArtCoupeTableau: number
  }) {
    const contents = await this.getContents(
      style,
      nbVersions,
      dys,
      theme,
      sousTheme,
      date,
      niveau,
      classe,
      corrige,
      twocolumn,
      beamerTwocolumn,
      didactiques,
      title,
      subtitle,
      reference,
      pixelArtUnite,
      pixelArtCsv,
      pixelArtAleatoire,
      pixelArtCoupeTableau
    )
    const content = contents.content
    const contentCorr = contents.contentCorr
    let result = ''
    if (style === 'PfMCan' || style === 'PfMFiche' || style === 'PfMBeamer' || style === 'PfMAmc' || style === 'PfMPixelArt') {
      console.log('rrr',content)
      result += content
    }
    return result
  }

  /**
   * Recupère les paquets et commandes supplémentaires dans le code des exercices
   * Ces éléments sont dans un tableau ou une chaine affecté à la propriété this.listePackages
   * Cette fonction sépare les deux. Dans le tableau les commandes sont précédées par 'cmd'
   * @returns {[Array,Array]} un tableau avec un tableau de paquets et un tableau de commandes
   */
  getContentLatex () {
    const packLatex : string[] = []
    for (const exo of this.exercices) {
      if (exo.listePackages) {
        if (typeof exo.listePackages === 'string') {
          packLatex.push(exo.listePackages)
        } else {
          packLatex.push(...exo.listePackages)
        }
      }
    }
    const packageFiltered : string[] = packLatex.filter((value, index, array) => array.indexOf(value) === index)

    const [latexCmds, latexPackages] = packageFiltered.reduce((result: [string[], string[]], element : string) => {
      result[element.startsWith('cmd') ? 0 : 1].push(element)
      return result
    },
    [[], []])

    return [latexCmds, latexPackages]
  }
}

function writeIntroduction (introduction = ''): string {
  let content = ''
  if (introduction.length > 0) {
    content += '\n' + format(introduction)
  }
  return content
}

function writeQuestions (questions: string[]): string {
  let content = ''
  if (questions !== undefined && questions.length > 1) {
    for (const question of questions) {
      content += '\n\t\\item ' + format(question)
    }
  } else {
    content += '\n' + format(questions[0])
  }
  return content
}

function writeInCols (text: string, nb: number): string {
  if (nb < 2) return text
  return `\\begin{multicols}{${nb}}${text}\n\\end{multicols}`
}

/**
 * Construire la liste des URLs pour les fichiers des images nécessaires
 * @param exosContentList
 * @param picsNames
 * @returns {Array} Un tableau de strings avec les urls des images
 *
 * @author Sébastien LOZANO et Christophe POULAIN
 */
export function buildImagesUrlsList (exosContentList: ExoContent[], picsNames: picFile[][]) {
  const imagesFilesUrls = [] as string[]
  exosContentList.forEach((exo, i) => {
    if (picsNames[i].length !== 0) {
      const year = exo.year
      const serie = exo?.serie?.toLowerCase()
      const month = exo.month
      const zone = exo.zone
      for (const file of picsNames[i]) {
        if (serie === 'crpe') {
          imagesFilesUrls.push(`./static/${serie}/${year}/${zone}/images/${file.name}.${file.format}`)
        } else {
          if (file.format) {
            imagesFilesUrls.push(`./static/${serie}/${year}/${month}/${zone}/images/${file.name}.${file.format}`)
          } else {
            imagesFilesUrls.push(`./static/${serie}/${year}/${month}/${zone}/images/${file.name}.eps`)
          }
        }
      }
    }
  })
  return imagesFilesUrls
}
/**
 * Construire la liste des URLs pour les fichiers *.dat nécessaires
 * @param exosContentList
 * @param datsNames
 * @returns {Array} Un tableau de strings avec les urls des fichiers *.dat
 *
 * @author Sébastien LOZANO et Christophe POULAIN
 */
export function buildDatsUrlsList (exosContentList: ExoContent[], datsNames: datFile[][]) {
  const datsFilesUrls = [] as string[]
  exosContentList.forEach((exo, i) => {
    if (datsNames[i].length !== 0) {
      const year = exo.year
      const serie = exo?.serie?.toLowerCase()
      const month = exo.month
      const zone = exo.zone
      for (const file of datsNames[i]) {
        if (serie === 'crpe') {
          datsFilesUrls.push(`./static/${serie}/${year}/${zone}/images/${file.name}.${file.format}`)
        } else {
          if (file.format) { // pour les fichiers dats pas de dossier spécifique
            datsFilesUrls.push(`./static/${serie}/${year}/${month}/${zone}/${file.name}.${file.format}`)
          } else {
            datsFilesUrls.push(`./static/${serie}/${year}/${month}/${zone}/${file.name}.dat`)
          }
        }
      }
    }
  })
  return datsFilesUrls
}

/**
 * Construire la liste des URLs pour les fichiers *.py nécessaires
 * @param exosContentList
 * @param pyNames
 * @returns {Array} Un tableau de strings avec les urls des fichiers *.pyt
 *
 * @author Sébastien LOZANO et Christophe POULAIN
 */
export function buildPythonsUrlsList (exosContentList: ExoContent[], pysNames: pyFile[][]) {
  const pysFilesUrls = [] as string[]
  exosContentList.forEach((exo, i) => {
    if (pysNames[i].length !== 0) {
      const year = exo.year
      const serie = exo?.serie?.toLowerCase()
      const month = exo.month
      const zone = exo.zone
      for (const file of pysNames[i]) {
        if (serie === 'crpe') {
          pysFilesUrls.push(`./static/${serie}/${year}/${zone}/images/${file.name}.${file.format}`)
        } else {
          if (file.format) { // pour les fichiers pythons pas de dossier spécifique
            pysFilesUrls.push(`./static/${serie}/${year}/${month}/${zone}/${file.name}.${file.format}`)
          } else {
            pysFilesUrls.push(`./static/${serie}/${year}/${month}/${zone}/${file.name}.py`)
          }
        }
      }
    }
  })
  return pysFilesUrls
}
/**
 * Constituer la liste des noms des images présentes dans le code de la feuille d'exercices.
 * ### Principe :
 * * Les deux variables globales `exosContentList` et `picsNames` servent à stocker le contenu de chaque
 * exercice et le nom de chaque images.
 * * Découpe le contenu du code LaTeX pour identifier les exercices en détectant
 * le texte entre les deux chaînes `\begin{EXO}` ... `\end{EXO}` (hormi les corrections où `\begin{EXO}`
 * est systématiquement suivi de `{}` vides)
 * * Dans le code de chaque exercice, on repère la commande `\includegraphics` dans les lignes non précédées d'un signe `%`
 * et on récupère le nom du fichier sans l'extension.
 * ### Remarques :
 * * `picsNames` est une tableau de tableaux au cas où des exercices contiendraient plusieurs figures
 * * les figures dans les corrections ne sont pas concernées.
 * @author sylvain
 */

export function getExosContentList (exercices: TypeExercice[]) {
  const exosContentList: ExoContent[] = []
  for (const exo of exercices) {
    let data: ExoContent = {}
    if (exo.typeExercice === undefined) {
      Object.assign(data, {}, { content: exo.contenu ?? '' })
    } else if (exo.typeExercice === 'simple') {
      Object.assign(data, {}, { content: exo.listeQuestions.join(' ') })
    } else {
      // Il faudrait ajouter contentCorr: exo.contentCorr ou quelque chose du genre ...
      data = { content: exo.content, serie: exo.examen, month: exo.mois, year: exo.annee, zone: exo.lieu, title: [exo.examen, exo.mois, exo.annee, exo.lieu].join(' ') }
    }
    exosContentList.push(data)
  }
  return exosContentList
}
/**
 * Récupère les noms des images
 * @param exosContentList
 * @returns
 */
export function getPicsNames (exosContentList: ExoContent[]) {
  const picsList = [] as RegExpMatchArray[][]
  const picsNames = [] as picFile[][]
  const regExpImage = /^(?:(?!%))(?:.*?)\\includegraphics(?:\[.*?\])?\{(?<fullName>.*?)\}/gm
  const regExpImageName = /(?<name>.*?)\.(?<format>.*)$/gm
  for (const exo of exosContentList) {
    let pics: RegExpMatchArray[]
    if (exo.content && exo.content.matchAll(regExpImage) !== undefined) {
      pics = [...exo.content.matchAll(regExpImage)]
      picsList.push(pics)
    } else {
      picsList.push([])
    }
  }
  picsList.forEach((list, index) => {
    picsNames.push([])
    if (list.length !== 0) {
      for (const item of list) {
        let imgObj
        if (item[1].match(regExpImageName)) {
          const imgFile = [...item[1].matchAll(regExpImageName)]
          if (imgFile[0].groups != null) { imgObj = { name: imgFile[0].groups.name, format: imgFile[0].groups.format } }
        } else {
          imgObj = { name: item[1], format: '' }
        }
        if (imgObj != null) {
          picsNames[index].push(imgObj)
        }
      }
    }
  })
  return picsNames
}
/**
 * Récupère les noms des fichiers *.dat
 * @param exosContentList
 * @returns
 */
export function getDatsNames (exosContentList: ExoContent[]) {
  const datsList = [] as RegExpMatchArray[][]
  const datsNames = [] as datFile[][]
  // const regExpImage = /.?\\begin\{filecontents\*\}(?:\[.*?\])?\{(?<fullName>.*?)\}/gm
  const regExpDat = /^.*Villes="(?<fullName>.*\.dat)"/gm
  const regExpDatName = /(?<name>.*?)\.(?<format>.*)$/gm
  for (const exo of exosContentList) {
    let dats: RegExpMatchArray[]
    if (exo.content && exo.content.matchAll(regExpDat) !== undefined) {
      dats = [...exo.content.matchAll(regExpDat)]
      datsList.push(dats)
    } else {
      datsList.push([])
    }
  }
  datsList.forEach((list, index) => {
    datsNames.push([])
    if (list.length !== 0) {
      for (const item of list) {
        let datObj
        if (item[1].match(regExpDatName)) {
          const datFile = [...item[1].matchAll(regExpDatName)]
          if (datFile[0].groups != null) { datObj = { name: datFile[0].groups.name, format: datFile[0].groups.format } }
        } else {
          datObj = { name: item[1], format: '' }
        }
        if (datObj != null) {
          datsNames[index].push(datObj)
        }
      }
    }
  })
  return datsNames
}
/**
 * Récupère les noms des fichiers python
 * @param exosContentList
 * @returns
 */
export function getPythonsNames (exosContentList: ExoContent[]) {
  const pysList = [] as RegExpMatchArray[][]
  const pysNames = [] as pyFile[][]
  // const regExpImage = /.?\\begin\{filecontents\*\}(?:\[.*?\])?\{(?<fullName>.*?)\}/gm
  // const regExpPython = /.(py?)$/gm
  const regExpPython = /^(?:(?!%))(?:.*?)\\PitonInputFile(?:\[.*?\])?\{(?<fullName>.*?)\}/gm
  const regExpPythonName = /(?<name>.*?)\.(?<format>.*)$/gm
  for (const exo of exosContentList) {
    let pys: RegExpMatchArray[]
    if (exo.content && exo.content.matchAll(regExpPython) !== undefined) {
      pys = [...exo.content.matchAll(regExpPython)]
      pysList.push(pys)
    } else {
      pysList.push([])
    }
  }
  pysList.forEach((list, index) => {
    pysNames.push([])
    if (list.length !== 0) {
      for (const item of list) {
        let datObj
        if (item[1].match(regExpPythonName)) {
          const pyFile = [...item[1].matchAll(regExpPythonName)]
          if (pyFile[0].groups != null) { datObj = { name: pyFile[0].groups.name, format: pyFile[0].groups.format } }
        } else {
          datObj = { name: item[1], format: '' }
        }
        if (datObj != null) {
          pysNames[index].push(datObj)
        }
      }
    }
  })
  return pysNames
}
/**
 * Détecter si le code LaTeX contient des images
 */
export function doesLatexNeedsPics (contents: { content: string, contentCorr: string }) {
  const includegraphicsMatches = contents.content.match('includegraphics')
  return includegraphicsMatches !== null
}

export function makeImageFilesUrls (exercices: TypeExercice[]) {
  const exosContentList = getExosContentList(exercices)
  const picsNames = getPicsNames(exosContentList)
  return buildImagesUrlsList(exosContentList, picsNames)
}

/**
 * Détecter si le code LaTeX contient des inclusions de fichiers *.dat
 */
export function doesLatexNeedsDats (contents: { content: string, contentCorr: string }) {
  const datsMatches = contents.content.match('Villes=')
  return datsMatches !== null
}

export function makeDatFilesUrls (exercices: TypeExercice[]) {
  const exosContentList = getExosContentList(exercices)
  const datsNames = getDatsNames(exosContentList)
  return buildDatsUrlsList(exosContentList, datsNames)
}

/**
 * Détecter si le code LaTeX contient des appels à des fichiers python
 */
export function doesLatexNeedsPythons (contents: { content: string, contentCorr: string }) {
  const pysMatches = contents.content.match('.py')
  return pysMatches !== null
}

export function makePythonFilesUrls (exercices: TypeExercice[]) {
  const exosContentList = getExosContentList(exercices)
  const pysNames = getPicsNames(exosContentList)
  return buildImagesUrlsList(exosContentList, pysNames)
}

/**
 * Pour les exercices Mathalea on a des conventions pour les sauts de ligne qui fonctionnent en HTML comme en LaTeX
 * * `<br>` est remplacé par un saut de paragraphe
 * * `<br><br>` est remplacé par un saut de paragraphe et un medskip
 */
export function format (text: string): string {
  if (text === undefined) return ''
  return text
    .replace(/(<br *\/?>[\n\t ]*)+<br *\/?>/gim, '\n\n\\medskip\n')
    .replace(/<br>/g, '\\\\')
    .replace(/\\\\\s*\n\n/gm, '\\\\')
}

/**
 * Récupère l'url d'un exercice
 * @param ex
 * @returns {URL}
 */
function getUrlFromExercice (ex: TypeExercice) {
  const url = new URL('https://aleaTeX.mathslozano.fr')
  if (ex.typeExercice === 'statique') {
    url.searchParams.append('uuid', String(`${ex.examen?.toLowerCase()}_${ex.annee}${ex.mois === undefined ? '' : `_${ex.mois}`}_${ex.lieu}_ex${ex.numeroInitial}`))
  } else {
    url.searchParams.append('uuid', String(ex.uuid))
  }
  if (ex.id !== undefined) url.searchParams.append('id', ex.id)
  if (ex.nbQuestions !== undefined) url.searchParams.append('n', ex.nbQuestions.toString())
  if (ex.duration !== undefined) url.searchParams.append('d', ex.duration.toString())
  if (ex.sup !== undefined) url.searchParams.append('s', ex.sup)
  if (ex.sup2 !== undefined) url.searchParams.append('s2', ex.sup2)
  if (ex.sup3 !== undefined) url.searchParams.append('s3', ex.sup3)
  if (ex.sup4 !== undefined) url.searchParams.append('s4', ex.sup4)
  if (ex.seed !== undefined) url.searchParams.append('alea', ex.seed)
  if (ex.interactif) url.searchParams.append('i', '1')
  if (ex.correctionDetaillee !== undefined) url.searchParams.append('cd', ex.correctionDetaillee ? '1' : '0')
  if (ex.nbCols !== undefined) url.searchParams.append('cols', ex.nbCols.toString())
  return url
}

export default Latex
