<script lang="ts">
  import * as ace from "brace";
  import "brace/mode/latex";
  import "brace/theme/textmate";

  import { exercicesParams } from "../stores/generalStore";
  import type Latex from "../../lib/Latex";
  import { tweened, type Tweened } from "svelte/motion";
  import {
    buildImagesUrlsList,
    doesLatexNeedsPics,
    getExosContentList,
    getPicsNames,
    buildDatsUrlsList,
    doesLatexNeedsDats,
    getDatsNames,
    getPythonsNames,
    doesLatexNeedsPythons,
    buildPythonsUrlsList,
    type LatexFileInfos,
  } from "../../lib/Latex";
  import Button from "./Button.svelte";
  import { onDestroy, onMount, tick } from "svelte";
  import {
    mathaleaGetExercicesFromParams,
    mathaleaUpdateExercicesParamsFromUrl,
  } from "../../lib/aleatex";
  import preambule from "../../lib/latex/preambule.tex?raw";
  import styleKawasakiFicheAmc from "../../lib/latex/styleKawasakiFicheAmc.tex?raw";
  import paquetsAdditionnels from "../../lib/latex/paquetsAdditionnels.tex?raw";
  import cedricPierquet from "../../lib/latex/cedricPierquet.tex?raw";
  import christophePoulain from "../../lib/latex/christophePoulain.tex?raw";
  import sebastienLozano from "../../lib/latex/sebastienLozano.tex?raw";
  import pourBeamer from "../../lib/latex/pourBeamer.tex?raw";
  import pourAmc from "../../lib/latex/pourAmc.tex?raw";
  import listeElevesFactice from "../../lib/latex/listeElevesFactice.csv?raw";
  import Harry from "../../lib/latex/Harry.csv?raw";
  import Mario from "../../lib/latex/Mario.csv?raw";
  import CitrouilleSorciere from "../../lib/latex/CitrouilleSorciere.csv?raw";
  import Joconde from "../../lib/latex/Joconde.csv?raw";
  // À commenter lorsque PfM est à jour sur TexLive
  import PfM from "../../lib/latex/ProfMaquette.sty?raw";
  import PfC from "../../lib/latex/ProfCollege.sty?raw";
  // import PfCStuffTeX from '../../lib/latex/PfCStuffTeX.tex?raw'
  // import PfCStuffMP from '../../lib/latex/PfCStuffMP.mp?raw'
  import type TypeExercice from "../../exercices/ExerciceTs.js";
  import { randint } from "../../modules/outils";

  export let contents = { content: "", contentCorr: "" };
  export let latex: Latex;
  export let latexFileInfos: LatexFileInfos;
  export const style: "PfMFiche" | "PfMCan" | "PfMBeamer" | "PfMAmc" | "PfMPixelArt" = "PfMFiche";

  let exercices: TypeExercice[];
  // Pour gérer l'affichage d'un message le temps de la compilation
  let clockAbled: boolean = false;
  const original = 1 * 60; // TYPE NUMBER OF SECONDS HERE
  let timer: Tweened<number>;
  // Moteur de compilation par défaut
  const defaultengine = "lualatex";
  // le retour en 'pdfjs' donne un pdf non modifiable
  // On met par défaut un retour 'pdf' ce qui permet d'annoter directement le pdf
  // const defaultreturn = 'pdf'
  // const defaultreturn = 'log'
  
  // Pour gérer la graine Beamer
  let seeds: [number]
  let seedMax = 1000
  //
  onMount(() => {
    window.addEventListener("keydown", handleKeyDown);
    // console.log('compilateur mounted')
    getExercices();
    updateEditor();    
    seeds = [randint(0,seedMax)]
    // isBacIn()
  });
  onDestroy(() => {
    window.removeEventListener("keydown", handleKeyDown);
  });
  function handleKeyDown(event: KeyboardEvent) {
    const isSaveShortcut =
      (event.ctrlKey || event.metaKey) && event.key === "s";
    if (isSaveShortcut) {
      event.preventDefault();
      compileToPDF("pdf");
    }
  }

  // ------ dont need to modify code below

  function addinput(f: HTMLFormElement, n: string, v: string) {
    const inp = document.createElement("input");
    inp.setAttribute("type", "text");
    inp.setAttribute("name", n);
    inp.value = encodeURIComponent(v);
    f.appendChild(inp);
  }

  function addinputnoenc(f: HTMLFormElement, n: string, v: string) {
    const inp = document.createElement("input");
    inp.setAttribute("type", "text");
    inp.setAttribute("name", n);
    inp.value = v;
    f.appendChild(inp);
  }

  function addtextarea(f: HTMLFormElement, n: string, v: string) {
    const inp = document.createElement("textarea");
    inp.setAttribute("type", "text");
    inp.setAttribute("name", n);
    inp.textContent = v;
    f.appendChild(inp);
  }

  function submitFormToIframe(formData: FormData) {
    const form = document.getElementById("form") as HTMLFormElement;
    form.innerHTML = "";
    // form.action = 'https://texlive.net/cgi-bin/latexcgi'
    // form.action = 'http://127.0.0.1:81/texlivenet/html/cgi-bin/latexcgi.pl'
    form.action = "https://tlnet.mathslozano.fr/cgi-bin/latexcgi.pl";
    form.method = "POST";
    form.target = "pre0ifr";
    form.enctype = "multipart/form-data";

    for (const [name, value] of formData.entries()) {
      if (name === "filecontents[]") {
        addtextarea(form, name, value.toString());
      } else if (name === "filename[]") {
        addinputnoenc(form, name, value.toString());
      } else {
        addinput(form, name, value.toString());
      }
    }
    form.style.display = "none";
    form.submit();
  }

  function resetIframe(): HTMLElement {
    const iframe = document.getElementById("pre0ifr") as HTMLElement;
    const parent = iframe.parentElement;
    parent?.removeChild(iframe);
    const iframe2 = document.createElement("iframe");
    iframe2.setAttribute("title", "output");
    iframe2.setAttribute("width", "100%");
    iframe2.setAttribute("height", "100%");
    iframe2.setAttribute("id", "pre0ifr");
    iframe2.setAttribute("name", "pre0ifr");
    parent?.appendChild(iframe2);
    return iframe2;
  }


  async function getMySeed(seeds: [number], max: number) {
    seeds.push(randint(0,max,seeds))
  }

  async function compileToPDF(defaultreturn: string) {
    const editor = ace.edit("editor");
    // On récupère le contenu de l'éditeur
    const t = editor.getValue();
    // Une regex pour récupérer les environnements exercice
    let exosRegExp = new RegExp(/\\begin{exercice}([^]*?)\\end{exercice}/gm);
    // Une regex pour récupérer les environnements Solution
    let solsRegExp = new RegExp(/\\begin{Solution}([^]*?)\\end{Solution}/gm);
    // Une regex pour récupérer l'ouverture de l'environnement Maquette avec ses paramètres
    let maquetteRegExp = new RegExp(/^\\begin{Maquette}(.*)/gm);
    // Une regex pour récupérer le contenu de l'environnement Maquette
    let contenuMaquetteRegExp = new RegExp(
      /\\begin{Maquette}([^]*?)\\end{Maquette}/gm,
    );
    // Une regex pour récupérer le contenu de l'environnement document
    let contenuDocumentRegExp = new RegExp(
      /\\begin{document}([^]*?)\\end{document}/,
    );
    // Une regex pour récupérer ce qui se trouve éventuellement entre l'ouverture de la Maquette et le commentaire du pemier exo
    let contenuEntreMaquetteEtPremierEXoRegExp = new RegExp(
      /\\begin{Maquette}([^]*?)% @see/,
    );
    const iframe2 = resetIframe();

    const formData = new FormData();
    // L'éditeur ne contient que le code entre \begin{document} et \end{document}
    // On ajoute le reste avant de passer au formulaire destiné à texlive.net
    // On initialise le code à transmettre
    let codeTex = "";
    // On ajoute le documentclass en amont pour pouvoir gérer l'option twocolumn
    switch (latexFileInfos.style) {
      case "PfMCan":
      case "PfMFiche":
      case "PfMPixelArt":
        codeTex += `%!TEX lualatex\n\\documentclass[french${latexFileInfos.twocolumn === true ? ",twocolumn" : ""}]{article}\n`;
        break;
      case "PfMBeamer":
        codeTex += `%!TEX lualatex\n\\PassOptionsToPackage{luatex}{hyperref}\n\\documentclass[french,t]{beamer}\n`;
        break;
      case 'PfMAmc':
        codeTex += `%!TEX lualatex\n\\documentclass[12pt,a4paper,french]{article}\n`
        break
      default:
        console.info(
          `Cas ${latexFileInfos.style} non prévu pour le style LaTeX`,
        );
    }
    // On ajoute le preambule
    switch (latexFileInfos.style) {
      case "PfMCan":
        codeTex += preambule
          .replace(
            "\\usepackage{ProfMaquette}",
            "\\usepackage[CAN]{ProfMaquette}",
          )
          .replace("a4paper", "a5paper")
          .replace("\\input{pourAmc.tex}","% On supprime l'appel au fichier pourAmc.tex")
          .replace('\\input{./commandes/pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
        break;
      case "PfMPixelArt":
      case "PfMFiche":
        codeTex += preambule
          .replace("\\input{pourAmc.tex}","% On supprime l'appel au fichier pourAmc.tex")
          .replace('\\input{./commandes/pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
        break;
      case "PfMBeamer":
        codeTex += preambule
          .replace(
            "\\usepackage[a4paper,margin=1cm,nohead,includefoot]{geometry}",
            "%",
          )
          .replace("\\setlength{\\parindent}{0pt}", "%")
          .replace("\\pagestyle{empty}", "%")
          .replace("\\input{pourAmc.tex}","% On supprime l'appel au fichier pourAmc.tex")
          .replace('\\input{./commandes/pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
        break;
        case "PfMAmc":
          codeTex += preambule
          .replace(
            "\\usepackage[a4paper,margin=1cm,nohead,includefoot]{geometry}",
            "%"
          )
          .replace("\\setlength{\\parindent}{0pt}", "%")
          .replace("\\pagestyle{empty}", "%")
          break;
      default:
        console.info(
          `Cas ${latexFileInfos.style} non prévu pour le style LaTeX`,
        );
    }
    // On teste si l'environnement document est correctement formé ou  présent
    if (!t.match(contenuDocumentRegExp)) {
      codeTex +=
        "La fenêtre de l'éditeur ne contient pas d'environnement document ou il est mal formé :( ";
    } else {
      if (
        !t.match(contenuMaquetteRegExp) &&
        !t.match(exosRegExp) &&
        !t.match(solsRegExp)
      ) {
        // On veut pouvoir ajouter tout le code de l'éditeur donc l'environnement document n'est pas ajouté ici
        codeTex += `\n${t}`;
      } else if (
        (!t.match(contenuMaquetteRegExp) &&
          t.match(exosRegExp) &&
          !t.match(solsRegExp)) ||
        (!t.match(contenuMaquetteRegExp) &&
          !t.match(exosRegExp) &&
          t.match(solsRegExp)) ||
        (!t.match(contenuMaquetteRegExp) &&
          t.match(exosRegExp) &&
          t.match(solsRegExp)) ||
        (t.match(contenuMaquetteRegExp) &&
          !t.match(exosRegExp) &&
          t.match(solsRegExp))
      ) {
        codeTex += `\n\\begin{document}\nil y a un problème avec au moins l'un des environnements :
        \\begin{itemize}
        \\item Maquette
        \\item exercice
        \\item Solution
        \\end{itemize}\n\\end{document}`;
      } else {
        // On ouvre l'environnement document
        // if (latexFileInfos.style === 'PfMBeamer') {
        //   codeTex += `\n\\setKVdefault[Boulot]{Graine=${seeds[seeds.length-1]}}`
        // }        
        codeTex += "\n\\begin{document}";
        // On ajoute une boucle s'il faut plus d'une version
        if (latexFileInfos.style !== 'PfMAmc' && latexFileInfos.nbVersions !== 1) {
          codeTex += `\n\\xintFor* #1 in {\\xintSeq{1}{${latexFileInfos.nbVersions}}}\\do{`;
        }
        if (latexFileInfos.style === 'PfMAmc') {
          codeTex += '\n\\csvreader[head to column names]{listeElevesFactice.csv}{}{%\n\\exemplaire{1}{%'
        }
        if (
          t.match(contenuMaquetteRegExp) &&
          t.match(exosRegExp) &&
          !t.match(solsRegExp)
        ) {
          // Maquette OK, des environnements exercices uniquement
          // On ouvre la Maquette
          // S'il y a du contenu entre l'ouverture de la Maquette et le commentaire du premier exo, on l'ajoute
          if (t.match(contenuEntreMaquetteEtPremierEXoRegExp)) {
            codeTex += `\n${t.match(contenuEntreMaquetteEtPremierEXoRegExp)[0].replace("% @see", "")}`;
            console.info(
              t
                .match(contenuEntreMaquetteEtPremierEXoRegExp)[0]
                .replace("% @see", ""),
            );
          } else {
            codeTex += `\n${t.match(maquetteRegExp)[0]}`;
          }
          // Pour Beamer, gestion de l'option double colonne
          if (
            latexFileInfos.beamerTwocolumn &&
            latexFileInfos.style === "PfMBeamer"
          ) {
            codeTex += "\n\\begin{multicols}{2}";
          }
          // Pour AMC, gestion de l'option double colonne
          if (
            latexFileInfos.twocolumn &&
            latexFileInfos.style === "PfMAmc"
          ) {
            codeTex += "\n\\begin{multicols}{2}";
          }
          let currentExo;
          // console.info(t.match(exosRegExp).length - 1);
          if (latexFileInfos.style === 'PfMPixelArt') {
            // codeTex += `\n\\begin{enumerate}[label=${latexFileInfos.pixelArtAleatoire === true ? '\\PfMPXCount' : '\\Alph{*}'}$\\blacktriangleright$,leftmargin=*,left=0pt]`
            codeTex += `\n\\begin{enumerate}[label=\\textbf{\\arabic{*}}$\\blacktriangleright$,leftmargin=*,left=0pt]`
          }
          for (let i = 0; i < t.match(exosRegExp).length; i++) {
            currentExo = `\n${t.match(exosRegExp)[i]}`;
            formData.append("filecontents[]", currentExo);
            formData.append("filename[]", `ex${i}.tex`);
            codeTex += `\n${latexFileInfos.style === 'PfMPixelArt' ? '\\item' : ''}\\input{ex${i}.tex}`;
            if (
              latexFileInfos.style === "PfMBeamer" &&
              i !== t.match(exosRegExp).length - 1
            ) {
              codeTex += `\n\\pause`;
            }
          }
          // Pour Beamer, gestion de l'option double colonne
          if (
            latexFileInfos.beamerTwocolumn &&
            latexFileInfos.style === "PfMBeamer"
          ) {
            codeTex += "\n\\end{multicols}";
          }
          // Pour Amc, gestion de l'option double colonne
          if (
            latexFileInfos.twocolumn &&
            latexFileInfos.style === "PfMAmc"
          ) {
            codeTex += "\n\\end{multicols}";
          }
          if (latexFileInfos.style === 'PfMPixelArt') {
            codeTex += '\n\\end{enumerate}'
          }
          // Ici dans tous les cas on ferme la maquette
          codeTex += "\n\\end{Maquette}";
        } else if (
          t.match(contenuMaquetteRegExp) &&
          t.match(exosRegExp) &&
          t.match(solsRegExp)
        ) {
          // Maquette OK, des environnements exercice et Solution
          // Il faut comparer le nombre d'environnements exercice et Solution
          if (t.match(exosRegExp).length !== t.match(solsRegExp).length) {
            codeTex += `\nil y a un problème avec au moins un environnement :
        \\begin{itemize}
        \\item il manque un environnement exercice
        \\item il manque un environnement Solution
        \\end{itemize}`;
          } else {
            // On ouvre la Maquette
            // S'il y a du cointenu entre l'ouverture de la Maquette et le commentaire du premier exo, on l'ajoute
            if (t.match(contenuEntreMaquetteEtPremierEXoRegExp)) {
              codeTex += `\n${t.match(contenuEntreMaquetteEtPremierEXoRegExp)[0].replace("% @see", "")}`;
              // console.info(t.match(contenuEntreMaquetteEtPremierEXoRegExp)[0].replace("% @see",""))
            } else {
              codeTex += `\n${t.match(maquetteRegExp)[0]}`;
            }
            // Pour Beamer, gestion de l'option double colonne
            if (
              latexFileInfos.beamerTwocolumn &&
              latexFileInfos.style === "PfMBeamer"
            ) {
              codeTex += "\n\\begin{multicols}{2}";
            }
            // Pour AMC, gestion de l'option double colonne
            if (
              latexFileInfos.twocolumn &&
              latexFileInfos.style === "PfMAmc"
            ) {
              codeTex += "\n\\begin{multicols}{2}";
            }
            let currentExo;
            if (latexFileInfos.style === 'PfMPixelArt') {
              // codeTex += `\n\\begin{enumerate}[label=${latexFileInfos.pixelArtAleatoire === true ? '\\PfMPXCount' : '\\Alph{*}'}$\\blacktriangleright$,leftmargin=*,left=0pt]`
              codeTex += `\n\\begin{enumerate}[label=\\textbf{\\arabic{*}}$\\blacktriangleright$,leftmargin=*,left=0pt]`
            }
            for (let i = 0; i < t.match(exosRegExp).length; i++) {
              currentExo = "";
              if (t.match(exosRegExp)[i]) {
                currentExo += `\n${t.match(exosRegExp)[i]}`;
              }
              if (t.match(solsRegExp)[i]) {
                currentExo += `\n${t.match(solsRegExp)[i]}`;
              }
              formData.append("filecontents[]", currentExo);
              formData.append("filename[]", `ex${i}.tex`);
              codeTex += `\n${latexFileInfos.style === 'PfMPixelArt' ? '\\item' : ''}\\input{ex${i}.tex}`;
              if (
                latexFileInfos.style === "PfMBeamer" &&
                i !== t.match(exosRegExp).length - 1
              ) {
                codeTex += `\n\\pause`;
              }
            }
            // Pour Beamer, gestion de l'option double colonne
            if (
              latexFileInfos.beamerTwocolumn &&
              latexFileInfos.style === "PfMBeamer"
            ) {
              codeTex += "\n\\end{multicols}";
            }
            // Pour AMC, gestion de l'option double colonne
            if (
              latexFileInfos.twocolumn &&
              latexFileInfos.style === "PfMAmc"
            ) {
              codeTex += "\n\\end{multicols}";
            }
            if (latexFileInfos.style === 'PfMPixelArt') {
              codeTex += '\n\\end{enumerate}'
            }
            // Ici dans tous les cas on ferme la maquette
            codeTex += "\n\\end{Maquette}";
          }
        } else {
          codeTex += "Pas prévu";
        }
        if (latexFileInfos.style !== 'PfMAmc' && latexFileInfos.nbVersions !== 1) {
          // On affiche la maquette suivante sur une nouvelle page
          codeTex += "\n\\clearpage";
          // On ferme la boucle
          codeTex += "\n}";
        } 
        if (latexFileInfos.style === 'PfMAmc') {
          codeTex += "\n}\n}";
        }
        // On ferme le document
        codeTex += "\n\\end{document}";
      }
    }
    // console.info(codeTex)
    formData.append("filecontents[]", codeTex);
    formData.append("filename[]", "document.tex");
    formData.append("engine", defaultengine);
    formData.append("return", defaultreturn);

    const contents = await latex.getContents(
      latexFileInfos.style,
      latexFileInfos.nbVersions,
      latexFileInfos.dys,
      latexFileInfos.theme,
      latexFileInfos.sousTheme,
      latexFileInfos.date,
      latexFileInfos.niveau,
      latexFileInfos.classe,
      latexFileInfos.corrige,
      latexFileInfos.twocolumn,
      latexFileInfos.beamerTwocolumn,
      latexFileInfos.didactiques,
      latexFileInfos.title,
      latexFileInfos.subtitle,
      latexFileInfos.reference,
      latexFileInfos.pixelArtUnite,
      latexFileInfos.pixelArtCsv,
      latexFileInfos.pixelArtAleatoire,
      latexFileInfos.pixelArtCoupeTableau
    );
    // On récupère les urls des images
    const picsWanted = doesLatexNeedsPics(contents);
    const exosContentList = getExosContentList(latex.exercices);
    const picsNames = getPicsNames(exosContentList);
    const imagesUrls: string[] = picsWanted
      ? buildImagesUrlsList(exosContentList, picsNames)
      : [];
    // On récupère les urls des fichiers dats
    const datsWanted = doesLatexNeedsDats(contents);
    const datsNames = getDatsNames(exosContentList);
    const datsUrls: string[] = datsWanted
      ? buildDatsUrlsList(exosContentList, datsNames)
      : [];
    // On récupère les urls des fichiers pythons
    const pythonsWanted = doesLatexNeedsPythons(contents);
    const pythonsNames = getPythonsNames(exosContentList);
    const pythonsUrls: string[] = pythonsWanted
      ? buildPythonsUrlsList(exosContentList, pythonsNames)
      : [];

    // https://stackoverflow.com/questions/71157367/how-to-wait-fetch-api-to-finish-using-async-await

    //==========================================================================================
    // En cas de besoin on peut fournir au serveur de compilation des fichiers necessaires
    // en attendant la maj de PfC et/ou PfM
    //==========================================================================================
    // On transmets temporairement ce qu'il faut pour tableur et Programme de calcul
    // Il faudra penser à supprimer après maj PfC et PfM dans TL2024
    //==========================================================================================
    // Laisser ici du code commenté pour anticiper l'ajout de fichiers de pré MAJ
    //==========================================================================================
    // formData.append('filecontents[]', PfCStuffTeX)
    // formData.append('filename[]', 'PfCStuffTeX.tex')
    // formData.append('filecontents[]', PfCStuffMP)
    // formData.append('filename[]', 'PfCStuffMP.mp')
    const myBoolBac = await isBacIn();
    // console.info(myBoolBac)
    // if (!myBoolBac) {
    //   console.info('Macros supplémentaires sauf pour le bac.')
    // } else {
    //   console.info('Macros supplémentaires pour le bac.')
    // }
    // // On ajoute temporairement le paquet PfM en attendant la maj
    // formData.append('filecontents[]', PfM)
    // formData.append('filename[]', 'ProfMaquette.sty')
    //==========================================================================================
    // On ajoute un fichier pour le style particulier
    formData.append("filecontents[]", styleKawasakiFicheAmc);
    formData.append("filename[]", "styleKawasakiFicheAmc.tex");
    // On ajoute un fichier pour les paquets additionnels
    formData.append("filecontents[]", paquetsAdditionnels);
    formData.append("filename[]", "paquetsAdditionnels.tex");
    // On ajoute un fichier pour les commandes de Cedric Pierquet
    formData.append("filecontents[]", cedricPierquet);
    formData.append("filename[]", "cedricPierquet.tex");
    // On ajoute un fichier pour les commandes de Christophe Poulain
    formData.append("filecontents[]", christophePoulain);
    formData.append("filename[]", "christophePoulain.tex");
    // On ajoute un fichier pour les commandes de Sebastien Lozano
    formData.append("filecontents[]", sebastienLozano);
    formData.append("filename[]", "sebastienLozano.tex");
    if (latexFileInfos.style == 'PfMBeamer') {
      // On ajoute un fichier pour les commandes pour Beamer
      formData.append("filecontents[]", pourBeamer);
      formData.append("filename[]", "pourBeamer.tex");
    }
    if (latexFileInfos.style == 'PfMAmc') {
      // On ajoute un fichier pour les commandes pour AMC
      formData.append("filecontents[]", pourAmc);
      formData.append("filename[]", "pourAmc.tex");
      // On ajoute un fichier csv pour la liste factice d'élèves
      formData.append("filecontents[]", listeElevesFactice);
      formData.append("filename[]", "listeElevesFactice.csv");
    }
    if (latexFileInfos.style == 'PfMPixelArt') {
      // On ajoute un fichier csv pour pixelArt
      switch (latexFileInfos.pixelArtCsv.filename) {
        case 'Mario.csv':
          formData.append("filecontents[]", Mario);
          formData.append("filename[]", "Mario.csv");
          break
        case 'Harry.csv':
          formData.append("filecontents[]", Harry);
          formData.append("filename[]", "Harry.csv");
          break
        case 'CitrouilleSorciere.csv':
          formData.append("filecontents[]", CitrouilleSorciere);
          formData.append("filename[]", "CitrouilleSorciere.csv");
          break
        case 'Joconde.csv':
          formData.append("filecontents[]", Joconde);
          formData.append("filename[]", "Joconde.csv");
          break
      }     
    }
    if (datsWanted) {
      //==========================================================================================
      for (let dat = 0; dat < datsUrls.length; dat++) {
        const dataUrl = datsUrls[dat].replace(
          "https://aleatex.mathslozano.fr",
          window.location.origin,
        );
        const visual = await fetch(dataUrl);
        const blob = await visual.blob();
        const stringfile = await blob.text();
        formData.append("filecontents[]", stringfile);
        formData.append("filename[]", dataUrl.split("/").slice(-1)[0]);
      }
    }
    if (pythonsWanted) {
      //==========================================================================================
      for (let python = 0; python < pythonsUrls.length; python++) {
        const dataUrl = pythonsUrls[python].replace(
          "https://aleatex.mathslozano.fr",
          window.location.origin,
        );
        const visual = await fetch(dataUrl);
        const blob = await visual.blob();
        const stringfile = await blob.text();
        formData.append("filecontents[]", stringfile);
        formData.append("filename[]", dataUrl.split("/").slice(-1)[0]);
      }
    }
    if (picsWanted) {
      for (let im = 0; im < imagesUrls.length; im++) {
        const imaUrl = imagesUrls[im].replace(
          "./",
          `${window.location.origin}/`,
        );
        // console.log(imaUrl)
        // console.log(imaUrl.split('/').slice(-1)[0])
        // console.log(imaUrl.split('/').slice(-1)[0].split('.').slice(-1)[0])
        const currentFilename = imaUrl.split("/").slice(-1)[0].split(".")[0];
        const currentFileExtension = imaUrl
          .split("/")
          .slice(-1)[0]
          .split(".")[1];
        // console.log(currentFilename)
        // console.log(currentFileExtension)
        if (currentFileExtension === "eps" || "mps") {
          const visual = await fetch(imaUrl);
          // console.log(visual)
          const blob = await visual.blob();
          // console.log(blob)
          const stringfile = await blob.text();
          // console.log(stringfile)
          formData.append("filecontents[]", stringfile);
          formData.append(
            "filename[]",
            `${currentFilename}.${currentFileExtension}`,
          );
        }
        if (currentFileExtension === "jpg" || "png") {
          // BINARY FILE DON'T SUPPORT => https://github.com/davidcarlisle/latexcgi/issues/22
          //=====================================================================
          // cette méthode ne fonctionne pas pour les fichiers png/jpg
          //=====================================================================
          // var visual_source = 'upload/file-source.png';
          // var visual_ext = visual_source.split('.').pop();
          // var visual = await fetch(visual_source);
          // console.log(visual);
          // var visual_as_blob = await visual.blob();
          // console.log(visual_as_blob);
          // var visual_as_file = new File([visual_as_blob], 'file-name-renamed.' + visual_ext, { lastModified: new Date().getTime(), type: visual_as_blob.type });
          // console.log(visual_as_file);
          // form_file.append('file', visual_as_file);
          // const visual = await fetch(imaUrl)
          // console.log(visual)
          // const blob = await visual.blob()
          // console.log(blob)
          // const stringfile = new File([blob], `${currentFilename}.${currentFileExtension}`, { lastModified: new Date().getTime(), type: blob.type});
          // const stringfile = await blob.text()
          // console.log(stringfile)
          // formData.append('filecontents[]', stringfile)
          // // formData.append('filename[]', imaUrl.split('/').slice(-1)[0])
          // formData.append('filename[]', `${currentFilename}.${currentFileExtension}`)
          // console.log(formData)
          // https://stackoverflow.com/questions/6150289/how-can-i-convert-an-image-into-base64-string-using-javascript
          // const reader = new FileReader()
          // reader.readAsDataURL(blob)
          // console.log(reader)
          // const stringfileB = await reader.result
          // console.log(stringfileB)
          // const base64String = await reader.result.replace("data:", "").replace(/^.+,/, "")
          // console.log(base64String)
          // formData.append('filecontents[]', stringfileB)
          // formData.append('filename[]', `${currentFilename}.${currentFileExtension}`)
          // console.log(formData)
        }
      }
    }
    // On affiche un message tant que la compilation est en cours
    timer = tweened(original);
    clockAbled = true;
    const timeValue = setInterval(() => {
      if ($timer > 0) {
        $timer--;
      } else {
        clearInterval(timeValue);
      }
    }, 1000);

    iframe2.addEventListener("load", function (this) {
      clockAbled = false;
      clearInterval(timeValue);
      timer = tweened(original);
    });

    submitFormToIframe(formData);
  }

  /**
   * Récupère les exercies depuis les paramètres de l'url et vérifie s'il y a des exercices de niveau BAC
   */
  async function isBacIn() {
    let myBool = false;
    mathaleaUpdateExercicesParamsFromUrl();
    exercices = await mathaleaGetExercicesFromParams($exercicesParams);
    // console.info(exercices)
    let ex = 0;
    do {
      if (exercices[ex].examen === "BAC") {
        myBool = true;
      }
      ex += 1;
    } while (ex < exercices.length);
    // console.info(isBacIn)
    return myBool;
  }

  /**
   * Récupère les exercies depuis les paramètres de l'url
   */
  async function getExercices() {
    mathaleaUpdateExercicesParamsFromUrl();
    exercices = await mathaleaGetExercicesFromParams($exercicesParams);
    latex.addExercices(exercices);
    contents = await latex.getContents(
      latexFileInfos.style,
      latexFileInfos.nbVersions,
      latexFileInfos.dys,
      latexFileInfos.theme,
      latexFileInfos.sousTheme,
      latexFileInfos.date,
      latexFileInfos.niveau,
      latexFileInfos.classe,
      latexFileInfos.corrige,
      latexFileInfos.twocolumn,
      latexFileInfos.beamerTwocolumn,
      latexFileInfos.didactiques,
      latexFileInfos.title,
      latexFileInfos.subtitle,
      latexFileInfos.reference,
      latexFileInfos.pixelArtUnite,
      latexFileInfos.pixelArtCsv,
      latexFileInfos.pixelArtAleatoire,
      latexFileInfos.pixelArtCoupeTableau
    );
    return contents;
  }

  /**
   * Mets à jour le code dans l'éditeur de la page d'export LaTeX
   */
  async function updateEditor() {
    exercices = await mathaleaGetExercicesFromParams($exercicesParams);
    contents = await latex.getContents(
      latexFileInfos.style,
      latexFileInfos.nbVersions,
      latexFileInfos.dys,
      latexFileInfos.theme,
      latexFileInfos.sousTheme,
      latexFileInfos.date,
      latexFileInfos.niveau,
      latexFileInfos.classe,
      latexFileInfos.corrige,
      latexFileInfos.twocolumn,
      latexFileInfos.beamerTwocolumn,
      latexFileInfos.didactiques,
      latexFileInfos.title,
      latexFileInfos.subtitle,
      latexFileInfos.reference,
      latexFileInfos.pixelArtUnite,
      latexFileInfos.pixelArtCsv,
      latexFileInfos.pixelArtAleatoire,
      latexFileInfos.pixelArtCoupeTableau
    );
    const picsWanted = doesLatexNeedsPics(contents);
    const exosContentList = getExosContentList(latex.exercices);
    const picsNames = getPicsNames(exosContentList);
    const imagesUrls: string[] = picsWanted
      ? buildImagesUrlsList(exosContentList, picsNames)
      : [];
    const editor = ace.edit("editor");
    editor.getSession().setMode("ace/mode/latex");
    editor.getSession().setNewLineMode("unix");
    editor.setTheme("ace/theme/textmate");
    editor.setShowPrintMargin(false);
    // On n'affiche pas le préambule
    // On sélectionne et on affiche uniquement le code modifiable dans l'éditeur LaTeX via une regex
    let corpsTexRegExp = new RegExp(
      /\\begin{document}([^]*?)\\end{document}/gm,
    );
    // editor.setValue(contents.content)
    editor.setValue(contents.content.match(corpsTexRegExp)[0]);
    editor.gotoLine(0);

    resetIframe();

    await tick();
    // On pourrait compiler directement au chargement de la modale mais je trouve préférable de laisser
    // l'utilisateur décider du moment de la compilation
  }
</script>

<!--
  @component
  Bouton déclenchant une compilation sur la page d'export LaTeX

  ### Paramètres

  * `latex` : code latex du document
  * `latexFileInfos` : objet contenant les éléments de mise en forme du fichier
  * `disabled` : flag permettant de désactiver le bouton

  ### Exemple
  ```tsx
  <CompilateurLatexOnline
    {latex}
    latexFileInfos={{
      style,
      nbVersions,
      dys,
      theme,
      sousTheme,
      date,
      niveau,
      classe,
      corrige,
      twocolumn,
      beamerTwocolumn,
      didactiques,
      title,
      subtitle,
      reference,      
      pixelArtUnite,
      pixelArtCsv,
      pixelArtAleatoire,
      pixelArtCoupeTableau
    }}
    disabled={false}
  />
  ```
 -->
<div class="mt-3 text-center">
  <!-- <div class="font-light"> -->
  <div
    class="font-light grid inline-grid grid-cols-1 grid-rows-1 md:grid-cols-2 xl:grid-cols-2 gap-8"
  >
    <Button
      isDisabled={clockAbled}
      class="px-2 py-1 rounded-md"
      title="Compiler le code ci-dessous en PDF directement ici."
      on:click={async () => {            
            compileToPDF("pdf")
            // await getMySeed(seeds,seedMax)
          }}
    />
    <Button
      isDisabled={clockAbled}
      class="px-2 py-1 rounded-md"
      title="Voir le log."
      on:click={() => compileToPDF("log")}
    />
  </div>
  <br />
  <div>
    {#if clockAbled}
      <div class="relative loader">
        <p>
          <b>Waiting for the compilation stuff Bro !!!</b>
          <br />
          <b>:)</b>
        </p>
        <div
          role="status"
          class="absolute -translate-x-1/2 -translate-y-1/2 top-[8vh] left-1/2"
        >
          <svg
            aria-hidden="true"
            class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ><path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            /><path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            /></svg
          >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    {/if}
  </div>
  <br />
  <div class="font-light">
    <div class="flex h-[80vh] flex-row max-md:portrait:flex-col">
      <div id="editor" class="flex flex-grow flex-1"></div>
      <div class="bg-gray-100 flex flex-grow flex-1">
        {#if clockAbled}
          <div class="relative loader">
            <p>
              <b>Waiting for the compilation stuff Bro !!!</b>
              <br />
              <b>:)</b>
            </p>
            <div
              role="status"
              class="absolute -translate-x-1/2 -translate-y-1/2 top-[30vh] left-1/2"
            >
              <svg
                aria-hidden="true"
                class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                ><path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                /><path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                /></svg
              >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        {/if}
        <iframe
          title="output"
          width="100%"
          height="100%"
          id="pre0ifr"
          name="pre0ifr"
        ></iframe>
      </div>
    </div>
    <div id="imagesLatex"></div>
    <form id="form"></form>
  </div>
</div>

<style>
  .loader {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
